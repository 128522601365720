import React from 'react'

const ServiceArea2 = () => {
  return (
    <div> 
<div className="services-area services-area2 section-padding40">
  <div className="container">
    <div className="row justify-content-sm-center">
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className="single-services mb-30">
          <div className="features-icon">
            <img src="assets/img/icon/icon1.svg" alt />
          </div>
          <div className="features-caption">
            <h3>60+ UX courses</h3>
            <p>The automated process all your website tasks.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className="single-services mb-30">
          <div className="features-icon">
            <img src="assets/img/icon/icon2.svg" alt />
          </div>
          <div className="features-caption">
            <h3>Expert instructors</h3>
            <p>The automated process all your website tasks.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-8">
        <div className="single-services mb-30">
          <div className="features-icon">
            <img src="assets/img/icon/icon3.svg" alt />
          </div>
          <div className="features-caption">
            <h3>Life time access</h3>
            <p>The automated process all your website tasks.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

</div>
  )
}

export default ServiceArea2