import logo from './logo.svg';
import './App.css';
import Home from './Components/Home';
import {Routes,Route} from 'react-router-dom';
import Courses from './Components/Courses';
import AboutPage from './Components/AboutPage';
import Blog from './Components/Blog';
import BlogDetails from './Components/BlogDetails';
import RegistrationPage from './Components/RegistrationPage';
import LoginPage from './Components/LoginPage';
import ContactPage from './Components/ContactPage';
function App() {
  return (
    <div className="App">
    <Routes>
      <Route path="/" element={  <Home/>}/>
      <Route path="/courses" element={<Courses/>}/>
      <Route path="/about" element={<AboutPage/>}/>
      <Route path='/blog' element={<Blog/>}/>
      <Route path="/blog_detail" element={<BlogDetails/>}/>
      <Route path='/register' element={<RegistrationPage/>}/>
      <Route path='/login' element={<LoginPage/>}/>
      <Route path='/contact' element={<ContactPage/>}/>
    </Routes>
    </div>
  );
}

export default App;
