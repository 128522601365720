import React from "react";
import Header from "./Header";
import BlogArea from "./BlogArea";
import Footer from "./Footer";
import BlogSideArea from "./BlogSideArea";

const Blog = () => {
  return (
    <div>
      <Header />
      <BlogSideArea/>
      <BlogArea />
      <Footer />
    </div>
  );
};

export default Blog;
