import React from 'react'

const RegistrationPage = () => {
  return (
    <div>
<main className="login-body" data-vide-bg="assets/img/login-bg.mp4">
<div className="video">
<video autoPlay muted loop id="background-video">
    <source src="assets/img/login-bg.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
 
  {/* Login Admin */}
  <form className="form-default" action="login-bg.mp4" method="POST">
    <div className="login-form">
      {/* logo-login */}
      <div className="logo-login">
        <a href="/"><img src="assets/img/logo/loder.png" alt /></a>
      </div>
      <h2>Registration Here</h2>
      <div className="form-input">
        <label htmlFor="name">Full name</label>
        <input type="text" name="name" placeholder="Full name" />
      </div>
      <div className="form-input">
        <label htmlFor="name">Email Address</label>
        <input type="email" name="email" placeholder="Email Address" />
      </div>
      <div className="form-input">
        <label htmlFor="name">Password</label>
        <input type="password" name="password" placeholder="Password" />
      </div>
      <div className="form-input">
        <label htmlFor="name">Confirm Password</label>
        <input type="password" name="password" placeholder="Confirm Password" />
      </div>
      <div className="form-input pt-30">
        <input type="submit" name="submit" defaultValue="Registration" />
      </div>
      {/* Forget Password */}
      <a href="login" className="registration">login</a>
    </div>
  </form>
  {/* /end login form */}
</main>

    </div>
  )
}

export default RegistrationPage