import React from 'react'
import Footer from './Footer'
import Header from './Header'
import Sliderarea from './Sliderarea'
import ServiceArea from './ServiceArea'
import CourseArea from './CourseArea'
import AboutArea1 from './AboutArea1'
import TopSubject from './TopSubject'
import AboutArea3 from './AboutArea3'
import TeamArea from './TeamArea'
import AboutArea2 from './AboutArea2'

const Home = () => {
  return (
    <div>
    {/* ? Preloader Start */}
{/* <div id="preloader-active">
  <div className="preloader d-flex align-items-center justify-content-center">
    <div className="preloader-inner position-relative">
      <div className="preloader-circle" />
      <div className="preloader-img pere-text">
        <img src="assets/img/logo/loder.png" alt />
      </div>
    </div>
  </div>
</div> */}
{/* Preloader Start */}

        <Header/>
        <Sliderarea/>
        <ServiceArea/>
        <CourseArea/>
        <AboutArea1/>
        <TopSubject/>
        <AboutArea3/>
        <TeamArea/>
        <AboutArea2/>
        <Footer/>
    </div>
  )
}

export default Home