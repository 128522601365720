import React from 'react'

const AboutArea1 = () => {
  return (
    <div>
<section className="about-area1 fix pt-10">
  <div className="support-wrapper align-items-center">
    <div className="left-content1">
      <div className="about-icon">
        <img src="assets/img/icon/about.svg" alt />
      </div>
      {/* section tittle */}
      <div className="section-tittle section-tittle2 mb-55">
        <div className="front-text">
          <h2 className>Learn new skills online with top educators</h2>
          <p>The automated process all your website tasks. Discover tools and 
            techniques to engage effectively with vulnerable children and young 
            people.</p>
        </div>
      </div>
      <div className="single-features">
        <div className="features-icon">
          <img src="assets/img/icon/right-icon.svg" alt />
        </div>
        <div className="features-caption">
          <p>Techniques to engage effectively with vulnerable children and young people.</p>
        </div>
      </div>
      <div className="single-features">
        <div className="features-icon">
          <img src="assets/img/icon/right-icon.svg" alt />
        </div>
        <div className="features-caption">
          <p>Join millions of people from around the world  learning together.</p>
        </div>
      </div>
      <div className="single-features">
        <div className="features-icon">
          <img src="assets/img/icon/right-icon.svg" alt />
        </div>
        <div className="features-caption">
          <p>Join millions of people from around the world learning together. Online learning is as easy and natural.</p>
        </div>
      </div>
    </div>
    <div className="right-content1">
      {/* img */}
      <div className="right-img">
        <img src="assets/img/gallery/about.png" alt />
        <div className="video-icon">
          <a className="popup-video btn-icon" href="https://www.youtube.com/watch?v=up68UAfH0d0"><i className="fas fa-play" /></a>
        </div>
      </div>
    </div>
  </div>
</section>

    </div>
  )
}

export default AboutArea1