import React from 'react'

const TopSubject = () => {
  return (
    <div>
        <div className="topic-area section-padding40">
  <div className="container">
    <div className="row justify-content-center">
      <div className="col-xl-7 col-lg-8">
        <div className="section-tittle text-center mb-55">
          <h2>Explore top subjects</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic1.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic2.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic3.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic4.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic5.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic6.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic7.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-4 col-sm-6">
        <div className="single-topic text-center mb-30">
          <div className="topic-img">
            <img src="assets/img/gallery/topic8.png" alt />
            <div className="topic-content-box">
              <div className="topic-content">
                <h3><a href="#">Programing</a></h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="section-tittle text-center mt-20">
          <a href="courses.html" className="border-btn">View More Subjects</a>
        </div>
      </div>
    </div>
  </div>
</div>

      
    </div>
  )
}

export default TopSubject