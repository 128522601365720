import React from 'react'

const LoginPage = () => {
  return (
    <div>
  <main className="login-body" data-vide-bg="assets/img/login-bg.mp4">
  <video autoPlay muted loop id="background-video">
    <source src="assets/img/login-bg.mp4" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
  {/* Login Admin */}
  <form className="form-default" action="login-bg.mp4" method="POST">
    <div className="login-form">
      {/* logo-login */}
      <div className="logo-login">
        <a href="index.html"><img src="assets/img/logo/loder.png" alt /></a>
      </div>
      <h2>Login Here</h2>
      <div className="form-input">
        <label htmlFor="name">Email</label>
        <input type="email" name="email" placeholder="Email" />
      </div>
      <div className="form-input">
        <label htmlFor="name">Password</label>
        <input type="password" name="password" placeholder="Password" />
      </div>
      <div className="form-input pt-30">
        <input type="submit" name="submit" defaultValue="login" />
      </div>
      {/* Forget Password */}
      <a href="#" className="forget">Forget Password</a>
      {/* Forget Password */}
      <a href="register" className="registration">Registration</a>
    </div>
  </form>
  {/* /end login form */}
</main>

    </div>
  )
}

export default LoginPage