import React from 'react'

const BlogSideArea = () => {
  return (
    <div>
             <section className="slider-area slider-area2">
        <div className="slider-active">
          {/* Single Slider */}
          <div className="single-slider slider-height2">
            <div className="container">
              <div className="row">
                <div className="col-xl-8 col-lg-11 col-md-12">
                  <div className="hero__caption hero__caption2">
                    <h1 data-animation="bounceIn" data-delay="0.2s">
                      Company insights
                    </h1>
                    {/* breadcrumb Start*/}
                    <nav aria-label="breadcrumb">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <a href="index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Blog</a>
                        </li>
                      </ol>
                    </nav>
                    {/* breadcrumb End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BlogSideArea