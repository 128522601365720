import React from 'react'
import Header from './Header'
import Footer from './Footer'
import Sliderarea from './Sliderarea'
import ServiceArea2 from './ServiceArea2'
import AboutArea1 from './AboutArea1'
import TopSubject from './TopSubject'
import AboutArea3 from './AboutArea3'
import TeamArea from './TeamArea'

const AboutPage = () => {
  return (
    <div>
    <Header/>
       <section className="slider-area slider-area2">
  <div className="slider-active">
    {/* Single Slider */}
    <div className="single-slider slider-height2">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-11 col-md-12">
            <div className="hero__caption hero__caption2">
              <h1 data-animation="bounceIn" data-delay="0.2s">About us</h1>
              {/* breadcrumb Start*/}
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                  <li className="breadcrumb-item"><a href="#">about</a></li> 
                </ol>
              </nav>
              {/* breadcrumb End */}
            </div>
          </div>
        </div>
      </div>          
    </div>
  </div>
</section>
<ServiceArea2/>
<AboutArea1/>
<TopSubject/>
<AboutArea3/>
<TeamArea/>
        <Footer/>
    </div>
  )
}

export default AboutPage